/* jshint unused:false */
/* jslint nomen: true */
/* global $, jQuery, _ */
(function( $ ){

  var methods = {
    init: function() {
      localStorage.setItem('user_credentials', $.cookie('user_credentials'));
      if ($.fluxx.config.portal_user) { $('#fluxx').addClass('portal'); }

      $.ios = {};

      // Global device detection function
      if (/(iPhone|Android)/i.test(navigator.appVersion) && !window.MSStream) {
        $.ios.iPhoneClient = true;
        $.ios.iPadClient = false;
        $('body').addClass('iphone');
        $.ios.defaults = {
          cardStops: [0, 270],
          cardThreshold: 120,
          closeCardThreshold: 200,
          maxTableAdjust: 195,
          maxDetailAdjust: 300
        };
      } else if (!window.MSStream && /MacIntel/.test(navigator.appVersion)) {
        $.ios.iPhoneClient = false;
        $.ios.iPadClient = true;
        $.ios.defaults = {
          cardStops: [68, 270],
          cardThreshold: 120,
          closeCardThreshold: 440,

          maxTableAdjust: 195,
          maxDetailAdjust: 300
        };
      }

      // Required to set some IOS 6 specific styles
      if (navigator.appVersion.match('OS 6')) { $('body').addClass('ios6'); }

      var setOrientation = function() {
        var orientation = window.orientation;
        if (orientation === 0 || orientation === 180) {
          $('body').addClass('portrait').removeClass('landscape');
        } else {
          $('body').removeClass('portrait').addClass('landscape');
        }
      };

      window.onorientationchange = function() {
        setOrientation();
        $.my.stage.resizeFluxxStage();
        $.my.cards.resizeFluxxCard();
        var $card = $('.card:visible:not(#popout)');
        if ($card.data('renderPlot')) {
          $card.find('#' + $card.data('chartID')).empty().end().data('renderPlot').call();
        }
      };
      setOrientation();

      $('#footer').append('<div class="toolbar"><a href="#logout" id="logout" title="Logout"/><a href="#" class="link edit-dashboard" title="Edit Dashboard"/></div>');
      $.my.stage.addClass('ios-dashboard');
      var touch = {},
        scroll = false,
        decorators = {
          'a.to-modal': {
            'touchstart': function(e) {
              $.fluxx.util.itEndsWithMe(e);

              var $elem = $(this),
                url = $elem.attr('href');

              if (!$elem.hasClass('disabled')){
                var header_title = $elem.attr('title') || $elem.html();

                $elem.openCardModal({
                  url:    url,
                  header: header_title,
                  target: $elem,
                  wide: $elem.hasClass('wide'),
                  hideFooter: $elem.hasClass('hide-footer'),
                  event: {pageX: e.originalEvent.touches[0].pageX, pageY: e.originalEvent.touches[0].pageY},
                });
              }
            }
          },
          '.edit-dashboard': {
            'touchstart': function(e) {
              methods.toggleEditMode();
            }
          },
          '.select-dashboard': {
            'click': function (e) {
              var $elem = $(this);
              $.fluxx.util.itEndsHere(e);
              $elem.find('.to-dashboard').click();
            }
          },
          '.toggle-toolbar-viz': {
            'touchstart': function(e) {
              if ($.my.footer.hasClass('minimized')) {
                tableAdjust = $.ios.defaults.cardStops[1];
                methods.maximizeToolbar();
              } else {
                tableAdjust = $.ios.defaults.cardStops[0];
                methods.minimizeToolbar();
              }
              $.my.cardTable[0].style.webkitTransform = 'translate3d('+ tableAdjust + 'px, 0, 0)';
              $.my.cardTable[0].style.WebkitTransition = '-webkit-transform .2s ease-out';
              $.my.cardTable.data('startX', tableAdjust);
            }
          },
          '.detail-action': {
            'click': function(e) {
              $.fluxx.util.itEndsHere(e);
              var $elem = $(this);
              $elem.closeCardModal();
              $elem.fluxxCard().find('.' + $elem.parent().attr('data-ref') + ' a').click();
            }
          },
          '#logo': {
            'click': function (e) {
              $.fluxx.util.itEndsHere(e);
            }
          },
          '#dashboard-picker-click': {
            'touchstart': function(e) {
              $.fluxx.util.itEndsHere(e);
              $.my.dashboardPicker.openManager();
            }
          },
          '.remove-card': {
            'click': function(e) {
              if (window.confirm('Are you sure you want to remove this card from the dashboard?')) {
                $(this).parent().fluxxCard().removeFluxxCard();
              }
            }
          },
          '.no-listing a.close-detail, a.close-card, a.cancel-close-card': {
            'click': function(e) {
              $.fluxx.util.itEndsHere(e);
              if (window.confirm('Are you sure you want to close this card?')) {
                $(this).fluxxCard().closeCardModal();
                $(this).fluxxCard().removeFluxxCard();
              }
            }
          },
          '.card-header .titlebar, .listing .footer': {
            'click': function(e) {
              if ($.ios.iPhoneClient) {
                $(this).fluxxCard().closeDetail();
              }
            }
          },
          '#updates': {
            'click': function(e) {
              e.preventDefault();
              $('.card:visible:not(#popout)').fluxxCardListing().trigger('get_update.fluxx.area');
            }
          },
          '.air-download': {
            'click': function(e) {
              $.fluxx.util.itEndsHere(e);

              // AML Detect wrapper version first
              // version 1.1 of the wrapper app support native preview
              if ($.hasWrapper && (parseInt($.hasWrapper) >= 1) && ($(this).data('preview-type') !== 'video')) {
                var $e = $(this),
                  url = $e.attr('href').split('/');

                //AML Remove absoltue part of URL
                url.splice(0,3);

                window.location = "/download/" + url.join('/') + '?' + $e.data('model-id') + ' ' + $(this).text();
              } else {
                $(this).prev('.actions').find('.to-preview').data({'preview-width': '100%', 'preview-position': ["0"]}).click();
              }
            }
          },
          '.add-card, .universal-search': {
            'click': function(e) {
              $.fluxx.util.itEndsWithMe(e);
              var $elem = $(this),
                url = $elem.attr('href');
              if (!$elem.hasClass('disabled')) {
                $elem.openCardModal({
                  url:    url,
                  header: $elem.attr('title'),
                  target: $elem,
                  event: e
                });
                $('.modal:last').find('input:visible').focus();
              }
            }
          },
          '.area .body, #viewport, #popcout .card .card-box, #ios-search-container .search-results, #ios-manager-container .manager-list': {
            'touchstart': function(e) {
              // Fix for IOS native scrolling
              // Allow bounce effect for divs already scrolled to the top or bottom
              var elem = this,
                startY = event.touches[0].pageY,
                startTopScroll = elem.scrollTop;
              if ($('.card.spreadsheet-card:visible')[0] || (elem.offsetHeight < elem.scrollHeight)) {
                scroll = true;
              } else {
                scroll = false;
              }

              if(startTopScroll <= 0) {
                elem.scrollTop = 1;
              }
              if(startTopScroll + elem.offsetHeight >= elem.scrollHeight) {
                elem.scrollTop = elem.scrollHeight - elem.offsetHeight - 1;
              }
            },
            'touchend': function(e) {
              scroll = false;
            }
          },
          // Allow the spreadsheet view to scroll horizontally
          '.fht-fixed-body': {
            'touchstart': function(e) {
              scroll = true;
              touch = {};
              e.stopPropagation();
            }
          },
          '#fluxx': {
            'touchmove': function(e) {
              if (!scroll) {
                e.preventDefault();
              }
            }
          },
          '.slide-left': {
            'click': function(e) {
              $.fluxx.util.itEndsHere(e);
              var $elem = $(this),
                $modal = $elem.closest('.modal'),
                $panel = $modal.find('.related-panel'),
                $header = $modal.find('.header:first');


              if ($elem.hasClass('lazy-load')) {

                $.ajax({
                  url: $elem.attr('data-src'),
                  beforeSend: function(jqXHR, settings) {
                    $modal.data('original', $modal.html());
                    $modal.data('originalHeight', $modal.height());
                    $modal.data('has-header', $modal.hasClass('show-header'));
                    $panel.html('');
                    $panel.addClass('loading');
                  },
                  complete: function(jqXHR, settings) {
                    $panel.removeClass('loading');
                  },
                  success: function(data, status, xhr){
                    $panel.html('<ul class="entries">' + data + '</ul>');

                    $panel.parents('.modal').addClass('show-header');
                    $panel.data('transform-ran', false);
                    $modal.find('.related-panel').height('100%');

                    //Adjust modal height as stencils load
                    $panel.find('.render-stencil').one('loaded.fluxx.stencil', function() {
                      methods.adjustModalheight($modal);
                    });

                    $panel.areaDetailTransform();
                    $elem.fluxxCard().hideLoadingIndicator();

                    //Display message if there is no related data
                    if (!$panel.find('.entry')[0]) {
                      $modal.find('.no-entries').html('');
                      $panel.html('<div class="no-entries">There are no ' + $elem.text() + ' associated with this record.</div>');
                      $modal.height(200);
                    } else {
                      // Trigger modal resize
                      $modal.trigger('refresh.fluxx.area');
                      methods.adjustModalheight($modal);
                    }

                  }
                });
              }

              $header.append('<span><a class="button back slide-right" href="#">Back</a> ' + $elem[0].firstChild.data + '</span>');
              $modal.addClass('show-header');
              setTimeout(function() {
                $header.addClass('slide-left');
              },1);
            }
          },
          '.slide-right': {
            'click': function(e) {
              $.fluxx.util.itEndsHere(e);
              var $elem = $(this),
                $modal = $elem.closest('.modal');

              if (!$modal.data('has-header')) $modal.removeClass('show-header');
              $modal.html($modal.data('original'));
              $modal.addClass('animate').height($modal.data('originalHeight'));
            }
          },
          '#popout': {
            'touchstart': function(e) {
              touch = {
                moved: false,
                elem: $(this),
                start: {
                  // get touch coordinates for delta calculations in onTouchMove
                  pageX: event.touches[0].pageX,
                  pageY: event.touches[0].pageY
                },
                // used for testing first onTouchMove event
                isScrolling: undefined,
                // reset deltaX
                deltaX: 0
              };
            },
            'touchmove': function(e) {
              // ensure swiping with one touch and not pinching
              if(event.touches.length > 1 || event.scale && event.scale !== 1) { return; }

              touch.deltaX = event.touches[0].pageX - touch.start.pageX;
              touch.moved = true;
              // determine if scrolling test has run - one time test
              if ( typeof touch.isScrolling === 'undefined') {
                touch.isScrolling = !!( touch.isScrolling || Math.abs(touch.deltaX) < Math.abs(event.touches[0].pageY - touch.start.pageY) );
              }

              // if user is not trying to scroll vertically
              if (!touch.isScrolling) {
                // prevent native scrolling
                e.preventDefault();
                if (touch.deltaX < 0) { touch.deltaX = 0; }
                touch.elem[0].style.webkitTransitionDuration = touch.elem[0].style.MozTransitionDuration = '0ms';
                touch.elem[0].style.MozTransform = touch.elem[0].style.webkitTransform = 'translate3d(' + touch.deltaX + 'px,0,0)';
                e.stopPropagation();
              }
            },
            'touchend': function(e) {
              if (!touch.moved) { return; }
              if (touch.deltaX >= touch.elem.width() / 2) {
                touch.elem.removeClass('open');
              }
              touch.elem.attr('style', '');
            }
          },
          '#logout': {
            'click': function(e) {
              $.fluxx.util.itEndsWithMe(e);
              if ($.hasWrapper) {
                window.location = "/settings";
              } else {
                if (window.confirm('You are about to log out of Fluxx.')) {
                  localStorage.setItem('user_credentials', '');
                  $.fluxx.sessionData('user_credentials', '');
                  $.cookie('user_credentials', '');
                  window.location = '/logout?t=' + new Date().getTime();
                }
              }
            }
          },
          '#card-table': {
            // Allow list and detail card areas to be dragged and positioned
            // TODO Rewrite this, it sucks
            'touchstart': function(e) {
              var $card = $('.card:visible:not(#popout)');
              touch = {
                moved: false,
                card: $card,
                listing: $card.find('.listing:visible').first(),
                start: {
                  // get touch coordinates for delta calculations in onTouchMove
                  pageX: event.touches[0].pageX,
                  pageY: event.touches[0].pageY
                },
                // used for testing first onTouchMove event
                isScrolling: undefined,
                // reset deltaX
                deltaX: 0,
                detail: {
                  element: $card.find('.detail:visible').first(),
                  startX: $('div.detail:visible').data('startX'),
                  currentX: $('div.detail:visible').data('startX'),
                  maxX: $.ios.iPadClient ? 336 : $(window).width() - 50
                },
                table: {
                  element: $(this),
                  startX: $.my.cardTable.data('startX'),
                  currentX: $.my.cardTable.data('startX')
                }
              };

              touch.canSlideDetail = (touch.detail.element[0] && touch.listing[0]) && !touch.card.data('detail-closed');
              // set transition time to 0 for 1-to-1 touch movement
              touch.table.element[0].style.MozTransitionDuration = touch.table.element[0].style.webkitTransitionDuration = 0;
              if (touch.canSlideDetail) {
                touch.detail.minX = $.ios.iPadClient ? $(window).width() - touch.detail.element.outerWidth(true) - 70 : 0;
                touch.detail.element[0].style.MozTransitionDuration = touch.detail.element[0].style.webkitTransitionDuration = 0;
              }
              if (touch.card.isMapCard()) {
                if (touch.start.pageY > 97 && touch.start.pageY < ($(window).height() - 46)) {
                  touch.isScrolling = true;
                }
              } else if (touch.card.isSpreadsheetCard()) {
                if (touch.start.pageY > 74 && touch.start.pageY < ($card.find('.fht-fixed-body').height() + 74)) {
                  touch.isScrolling = true;
                }
              }
              e.stopPropagation();
            },
            'touchmove': function(e) {
              // Don't allow card to slide around if scrolling spreadsheet or map view
              // ensure swiping with one touch and not pinching
              if (!touch.start || touch.isScrolling || event.touches.length > 1 || event.scale && event.scale !== 1 ) {
                return;
              }
              touch.deltaX = event.touches[0].pageX - touch.start.pageX;
              touch.moved = true;

              // determine if scrolling test has run - one time test
              if ( typeof touch.isScrolling === 'undefined') {
                touch.isScrolling = !!( touch.isScrolling || Math.abs(touch.deltaX) < Math.abs(event.touches[0].pageY - touch.start.pageY) );
              }

              // if user is not trying to scroll vertically
              if (!touch.isScrolling) {
                // prevent native scrolling
                e.preventDefault();
                // Slide the detail area
                var slide = (touch.deltaX + touch.detail.startX);
                var elem;
                if (touch.canSlideDetail && touch.table.currentX <= $.ios.defaults.cardStops[0] && slide <= (touch.detail.maxX)) {
                  slide += (touch.table.startX - touch.table.currentX);
                  elem = touch.detail.element[0];

                  if (slide < touch.detail.minX) {
                    slide = touch.detail.minX - (touch.detail.minX - slide) / 2;
                  }

                  // translate immediately 1-to-1
                  elem.style.MozTransform = elem.style.webkitTransform = 'translate3d(' + slide + 'px,0,0)';
                  // TB - Not sure what the line below was originally meant to accomplish?
                  // $.ios.defaults.cardStops[1]
                  touch.detail.currentX = slide;

                  // Slide Card Table
                } else {
                  elem = $.my.cardTable[0];
                  if (touch.canSlideDetail ) {
                    if (slide > touch.detail.maxX) {
                      touch.detail.currentX = touch.detail.maxX;
                      touch.detail.element[0].style.MozTransform = touch.detail.element[0].style.webkitTransform = 'translate3d(' + touch.detail.currentX + 'px,0,0)';
                    }
                    touch.deltaX -= (touch.detail.currentX - touch.detail.startX);
                  }

                  slide = (touch.deltaX + touch.table.startX);

                  // Implement stops
                  if (slide < $.ios.defaults.cardStops[0]) {
                    slide = $.ios.defaults.cardStops[0] - ($.ios.defaults.cardStops[0] - slide) / 2;
                  }
                  if (slide > $.ios.defaults.cardStops[1]) {
                    slide = $.ios.defaults.cardStops[1] + (slide - $.ios.defaults.cardStops[1]) / 2;
                  }
                  // translate immediately 1-to-1
                  elem.style.MozTransform = elem.style.webkitTransform = 'translate3d(' + slide + 'px,0,0)';
                  touch.table.currentX = slide;
                }
                e.stopPropagation();
              }
            },
            'touchend': function(e) {
              if (!touch.moved) { return; }
              e.preventDefault();
              var elem = touch.table.element[0],
                closeDetail = !touch.canSlideDetail;

              // Snap to fixed positions
              touch.table.currentX =  (touch.table.currentX < $.ios.defaults.cardThreshold) ? $.ios.defaults.cardStops[0] : $.ios.defaults.cardStops[1];

              elem.style.MozTransitionDuration = elem.style.webkitTransitionDuration = '300ms';
              elem.style.MozTransform = elem.style.webkitTransform = 'translate3d(' + touch.table.currentX + 'px,0,0)';

              if (!closeDetail) {
                elem = touch.detail.element[0];
                if (touch.detail.currentX < ((touch.detail.maxX - touch.detail.minX)/2) + touch.detail.minX) {
                  touch.detail.currentX = touch.detail.minX;
                  elem.style.MozTransitionDuration = elem.style.webkitTransitionDuration = '300ms';
                  elem.style.MozTransform = elem.style.webkitTransform = 'translate3d(' + touch.detail.currentX + 'px,0,0)';

                } else {
                  touch.detail.currentX = touch.detail.maxX;
                  elem.style.MozTransitionDuration = elem.style.webkitTransitionDuration = '300ms';
                  elem.style.MozTransform = elem.style.webkitTransform = 'translate3d(' + touch.detail.currentX + 'px,0,0)';
                }
                touch.detail.element.data('startX', touch.detail.currentX);
              }
              $.my.cardTable.data('startX', touch.table.currentX);
              if (touch.table.currentX ==  $.ios.defaults.cardStops[0]) {
                methods.minimizeToolbar();
              } else {
                methods.maximizeToolbar();
              }
            }
          }
        };

      _.each(decorators, function(val, key) {
        $(key).live(val);
      });

      $.fluxx.dashboard.manager.init = function() {

        var $dashboards = $.my.dashboardPicker
          .find('.item')
          .clone(true)
          .wrapAll('<ul class="manager-list" />')
          .parent()
          .before('<div class="header"><div class="close-modal simplemodal-close">< Back</div><div class="manager-title">Dashboards</div><div class="edit-dashboards">Edit</div></div><div class="new-dashboard-container"><a href="#" class="new-dashboard">New Dashboard</a></div>');

        $dashboards.find("li a").each(function(){
          if ($(this).text().length > 25){
            var literal = $.trim($(this).text()).substring(0, 25).split(" ").slice(0, -1).join(" ") + "...";
            $(this).text(literal)
          }
        });


        $dashboards.find('li').each(function() {
          var $elem = $(this).addClass('select-dashboard');
          var $item = $elem.find('a');
          var dashboard = $item.data('dashboard');
          if (dashboard.data) {
            var mandated = dashboard.mandated;
            $item.after(
              '<ul class="actions">' +
              (mandated ? '' : '<li><a href="#" class="rename-dashboard"/></li>') +
              (mandated ? '' : '<li><a href="#" class="delete-dashboard"/></li>') +
              '</ul><div class="manager-card-count">' +
              _.reject(dashboard.data.cards, function(card) {
                return card.settings.plugins && (card.settings.plugins.indexOf("popoutCard") > -1 || card.settings.plugins.indexOf("sharedCards") > -1 || card.settings.plugins.indexOf("backgroundJobs") > -1);
              }).length +
              ' cards' +
              '</div>');
//                # loop through all card and look exclude the following plugins: popoutCard, sharedCards, backgroundJobs
          }
        });
        //$dashboards.find('.manager-list').after('<div class="new-dashboard"><a href="#" class="new-dashboard">New</a></div>');
        $dashboards.modal({
          closeHTML: "",
          position: ['0px','10px'],
          minWidth: 250,
          maxWidth: 250,
          minHeight: '100%',
          maxHeight: '100%',
          overlayId: 'ios-manager-overlay',
          containerId: 'ios-manager-container',
        });

        $dashboards.find('.edit-dashboards').click(function() {
          $('#ios-manager-container').toggleClass('edit-mode');
          if ($('#ios-manager-container').hasClass('edit-mode')) {
            $dashboards.find('.edit-dashboards').text('Cancel');
          } else {
            $dashboards.find('.edit-dashboards').text('Edit');
          }


        });

      };


      $.fluxx.dashboard.manager.initNewDashboardModal = function (templates) {
        $('#ios-manager-container').removeClass('saving').removeClass('edit-mode');
        var dashboards = '<li data-tick="✓" class="item"><a class="new-dashboard from-template" href="#">New Blank Dashboard</a></li>',
            data = [],
            $container = $('#ios-manager-container');

        $.each(templates, function(i, obj) {
          var numCards = 0;
          var template = null;
          try {
            if (obj.dashboard_template.data ) {
              template = $.parseJSON(obj.dashboard_template.data);
              obj.dashboard_template.data = template.data;
            }
            data.push(template);
            if (template && template.data && template.data.cards) {
              numCards = _.reject(template.data.cards, function(card) {
                return card.settings.plugins && (card.settings.plugins.indexOf("popoutCard") > -1 || card.settings.plugins.indexOf("sharedCards") > -1 || card.settings.plugins.indexOf("backgroundJobs") > -1);
              }).length;
            }
          } catch(e) {

          }
          locked = obj.dashboard_template.locked ? '<img src="/images/fluxx_engine/theme/default/icons/lock.png" height="14px"> ' : '';
          dashboards += '<li data-tick="✓" class="item"><a class="new-dashboard from-template" data-locked="' + (locked != '') + '"data-dashboard-template-name="' + obj.dashboard_template.name + '"data-dashboard-template-fullname="' + obj.dashboard_template.fullname + '" data-dashboard-template-id="' + i + '" href="/dashboard_templates/' + obj.dashboard_template.id +'">' +locked + obj.dashboard_template.name + '</a>' +
          '<ul class="actions">' +
          '<li><a href="#" class="rename-dashboard dashboard-template"></a></li>' +
          '<li><a class="delete-dashboard dashboard-template"></a></li>' +
          '</ul><div class="manager-card-count">' +
          numCards +
          ' cards</div></li>';
        });
        var $dashboards = $('<div><div class="header"><div class="new-dashboard-title">Create New Dashboard</div></div><ul class="manager-list">' + dashboards + '</ul><div class="cancel-new-dashboard-container"><a href="#" class="cancel-new-dashboard">Cancel</a></div></div>');
        $container.html($dashboards.html());
        $container.find('.cancel-new-dashboard').click(function() {
          $.modal.close();
          setTimeout(function() {
            $.fluxx.dashboard.manager.init();
          }, 500);

        });


        $('body').data('dashboard-templates', templates);
      };

      return true;
    },
    newDashboard: function(e) {
      $('#ios-manager-container').addClass('saving');
    },
    maximizeToolbar: function() {
      $.my.footer.removeClass('minimized');
      $.my.header.removeClass('minimized');
    },
    minimizeToolbar: function() {
      methods.closeEditMode();
      $.my.footer.addClass('minimized');
      $.my.header.addClass('minimized');

    },
    toggleEditMode: function() {
      if ($.my.footer.hasClass('edit-mode')) {
        methods.closeEditMode();
      } else {
        methods.openEditMode();
      }
    },
    openEditMode: function() {
      $.my.footer.addClass('edit-mode');
      $('#iconlist').sortable("enable");

    },
    closeEditMode: function() {
      $.my.footer.removeClass('edit-mode');
      $('#iconlist').sortable("disable");
    },
    addFluxxDock: function(options, onComplete) {
      $.my.dock = $.fluxx.dock.ui.call($.my.footer, options)
        .appendTo($.my.footer);
      $.my.viewport = $('#viewport');
      $.my.iconlist = $('#iconlist');
      $.my.quicklinks = $('#quicklinks');
      $.my.lookingGlass = $('#lookingglass');
      $.my.cardTable = $('#card-table');

      $.my.iconlist = $('#iconlist').sortable({
        axis: "y",
        update: function (event, ui) {
          var itemID = $('a', ui.item).attr('href').replace(/^#/,'');
          var nextID = $('a', ui.item.next()).attr('href');
          var $card = $('#' + itemID);
          if (typeof nextID != 'undefind' && nextID) {
            var $nextCard = $('#' + nextID.replace(/^#/,''));
            $card.detach().insertBefore($nextCard);
          } else {
            $card.detach().insertAfter($.my.cards.last());
          }
          $.my.cards = $('#hand .card');
          $.my.stage.resizeFluxxStage();
          $card.saveDashboard();
        }
      }).sortable( "disable" );


      $.my.dock
        .bind({
          'complete.fluxx.dock': _.callAll(options.callback, $.fluxx.util.itEndsWithMe)
        })
        .trigger('complete.fluxx.dock');
      $.my.stage.bind('resize.fluxx.stage', $.my.dock.fluxxDockUpdateViewing);
      $(window).scroll($.my.dock.fluxxDockUpdateViewing);
      $(window).bind('complete.fluxx.dashboard', function() {
        var $lastCard = $.my.iconlist.find('a[href="' + localStorage.lastCard + '"]');
        if ($lastCard[0]) {
          $lastCard.click();
        } else {
          // Select the first card
          $.my.iconlist.find('li:first a').click();
        }
      });

      // Move universal search to the bottom toolbar
      //TODO
      //$.my.dock.find('.link.universal-search').attr('class', 'universal-search link').attr('href', '#universal-search-modal').insertAfter('#open-dashboard-picker');

      $('#header .actions').after('<div id="dashboard-picker-click"/>');
      // Move the plus menu
      $.my.dock.find('.icon.plus .link').attr('class', 'add-card link').attr('href', '#quicklinks .icon.plus .popup').insertAfter('#iconlist').find('.label').text('New Card');

      // Tell the wrapper application to hide the loading indicator
      if ($.hasWrapper) {
        window.location = "/loaded";
      }
      return this;
    },
    afterAddFluxxCard: function(options) {
      var $card = $(this);
      if ($card.hasPlugin('popoutCard')) {
        $card.fluxxPagination();
      } else {
        $card.bind('update.fluxx.card', function() {
          $card.callPlugin('setUpdateArea');
        }).find('.card-header .buttons li a').removeClass('disabled');
        if (!$card.fromClientStore()) {
          $card.callPlugin('scrollToCard');
        }
      }
      return false;
    },
    removeFluxxCard: function(options, onComplete) {
      methods.maximizeToolbar();
    },
    placeSubmitButton: function($submit, $area, $form, $flows) {
      if (!$area.hasClass('detail')) return;
      $area.find('.detail-actions').hide();


      $flows = $('<ul class="workflow"/>').appendTo($area.find('.header'));

      //Make sure we can actually cancel!
      if (/\/new\?/.test($area.attr('data-src'))) {
        $flows.append('<li class="cancel-button"><a href="#" class="cancel-close-card">Cancel</a></li>');
      } else {
        var $cancel = $area.find('.footer .unlock');
        $flows.append($cancel.parent().addClass('cancel-button'));
      }

      var locked = $form.data('locked');
      if (!$submit.hasClass('ignore') && !$form.hasClass('no-submit-button')) {
        $flows.find('.submit').remove();
        var $link= $('<a>Save</a>').attr('href', $form.attr('action')).unbind('click').bind('click', function(e){
          $.fluxx.util.itEndsWithMe(e);
          if (locked) return false;
          var $over_limit_areas = $form.find('.counter.limit_exceeded');

          if ($over_limit_areas[0]) {
            $form.fluxxCard().find('.detail .header').removeClass('empty').html('<div class="notice error"><a class="close-parent" href="#fluxx-card-notice"><img src="/images/fluxx_engine/theme/default/icons/cancel.png" alt="close"></a>Errors were found. Error messages are displayed near each form field below. </div>');
            $form.fluxxCard().resizeFluxxCard();
          } else {
            if ($submit.hasClass('to-javascript')) {
              var func = $submit.attr('data-javascript-function');
              if (func && (typeof $.fluxx.utility[[func]] == "function")) {
                $.fluxx.utility[[func]]($submit, $form);
              }
            } else {
              // Prevent submitting the form multiple times
              if ($form.data('submitting'))
                return;
              $form.data('submitting', true);
              $form.submit();
            }
          }
        }).wrap('<li class="submit' + (locked ? ' disabled' : '') + '"/>').parent().appendTo($flows);

        if ($submit.hasClass('to-javascript')) $form.submit(function(e) {
          if ($submit.hasClass('to-javascript')) $.fluxx.util.itEndsWithMe(e);
        });

        $('.footer', $area).removeClass('empty');
      }
      if (!$submit.hasClass('ignore') && $('.footer', $area)[0])
        $submit.hide();
      $area.find('.notice.error').prependTo($area.find('.body'));
      $area.on('validation-error', function() {
        $area.find('.body .notice.error').remove();
        $area.find('.notice.error').prependTo($area.find('.body'));
      });
      return true;
    },
    afterFluxxCardLoadContent: function(options) {
      var $area = options.area;

      var $notice = $('.notice:not(.error)').remove();
      if ($notice[0]) {
        $notice.appendTo('#fluxx');
        setTimeout(function() {
          $notice.fadeOut(function() {
            $notice.remove();
          });
        }, 3000);
      }


      if ($area.hasClass('detail')) {
        $area.find('.detail-actions').remove();
        var $wf = $area.find('.footer .buttons li, .footer .workflow li');
        if ($wf[0]) {
          $area.find('.detail-actions-panel').remove();
          var $panel = $('<div class="detail-actions-panel"><ul></ul></div>');
          $wf.each(function () {
            if (!$(this).find('a').hasClass('unlock') && !$(this).find('a').hasClass('no-mobile') && !$(this).find('a').hasClass('listing-toolbar')) {
              var $elem = $(this).clone(),
                id = _.uniqueId('action_');

              if ($elem.children().length) {
                $panel.find('ul').append($elem);
                $(this).addClass(id);
                $elem.attr('data-ref', id);
                if ($(this).parent().hasClass('workflow')) $elem.addClass('workflow-action');
              }
            }
            $panel.find('a').addClass('detail-action');
          });
          $area.append($panel);
          $area.find('.footer .buttons').append('<li class="detail-actions"><a href=".detail-actions-panel" class="listing-toolbar to-modal"></a></li>');
        }
      }

      if (!$area.hasClass('listing')) {
        return $area;
      }
      var $add = $area.fluxxCardListing().find('.body .card-actions a.button');
      if ($add[0]) {
        $area.fluxxCardListing().find('.header:first').prepend('<ul class="button"><li><a href="' + $add.attr('href') + '" class="to-modal" data-on-success="close">' + $add.attr('title') + '</a></li></ul>');
      }
      $area.find('.header .actions').find('li').find('.open-subscriptions')
        .attr('title', ($area.fluxxCard().data('emailNotifications') ? 'Unsubscribe From Card' : 'Subscribe To Card')).end()
        .find('.open-listing-actions').parent().remove().end().end()
        .each(function() {
          var $elem = $(this);
          $elem.find('a').text($elem.find('a').attr('title'));
        });

    },
    openSubscriptions: function() {
      var $card = $(this),
        enabled = $card.data('emailNotifications');
      $card.data('emailNotifications', !enabled);
      $card.trigger('lifetimeComplete.fluxx.card');
      $card.closeCardModal();
      $card.saveDashboard();
      $card.find('.open-subscriptions').text(($card.data('emailNotifications') ? 'Unsubscribe From Card' : 'Subscribe To Card'));

      return true;
    },
    setupRelatedData: function($area) {
      var $card = $(this);
      if (!$area.find('#show-related')[0]) {
        var $sections = $('.drawer', $area.fluxxCard());
        if (!$sections.parent().hasClass('related-panel')) $sections.wrap('<div class="related-panel simple-list"/>');
        var i = 1,
          hasRelated = false;
        $sections.find('.section').each(function() {
          var $section = $(this),
            $label = $section.find('.label').detach(),
            sectionID = 'entry_' + i++,
            count = $section.find('ol.entries').attr('id', sectionID).find('li').length;

          if (count > 0) {
            $section.append('<a href="#' + sectionID + '" class="slide-left">' + $label.text() + '<span class="count">' + count + '</span></a>');
            hasRelated = true;
          } else if ($label.hasClass('lazy-load')) {
            $section.append('<a href="#' + sectionID + '" class="slide-left lazy-load" data-src="' + $label.attr('data-src') + '">' + $label.text() + '</a>');
            hasRelated = true;
          } else {
            $section.remove();
          }
        });
        if (hasRelated) {
          $area.find('.footer .buttons').append('<li id="show-related"><a href=".related-panel" class="listing-toolbar to-modal">Related</a></li>');
        }
      }
    },
    //setUpdateArea: function() {
    //  var $card = $(this);
    //  if ($card.is(':visible')) {
    //    $.my.updates.html('<div class="updates">' + $card.find('.card-header .toolbar .updates').html() + '</div>');
    //    if ($card.hasClass('updates-available')) {
    //      $.my.updates.addClass('updates-available');
    //    } else {
    //      $.my.updates.removeClass('updates-available');
    //    }
    //  }
    //},
    lifetimeComplete: function() {
      var $card = $(this),
        view = $card.fluxxCardView();
      if (view) {
        $card.addClass(view + '-card');
      }
      if ($card.data && $card.data('icon')) {
        $card
          .setMinimizedProperties({info: $card.fluxxCardInfo()})
          .data('icon').setDockIconProperties({
            style: $card.fluxxCardIconStyle(),
            popup: $card.fluxxCardInfo()
          });
        // Add a close detail button
        if ($card.fluxxCardDetail()[0]) {
          var closeCard = $card.fluxxCardDetail().find('.close-card');
          if (!closeCard[0]) $card.fluxxCardDetail().append('<a href="#" class="close-card"></a>');
          if (closeCard.is(':hidden')) closeCard.show();

        }
      }
      return true;
    },
    showFluxxCard: function(options) {
      return true;
    },
    focusFluxxCard: function(options, onComplete) {
      return true;
    },
    positionCard: function() {
      return true;
    },
    insertViewPortIcon: function($icon, options) {
      var $card = $(this);
      if ($card.hasPlugin('popoutCard')) {
        $('#open-signals').remove();
        return $icon.find('a').attr('id', 'open-signals').insertAfter('#footer .toolbar a:first');
      }
      $icon.prepend('<div class="remove-card"/>');
      return null;
    },
    scrollToCard: function() {
      var $card = $(this);
      if ($card.hasPlugin('popoutCard')) {
        $card.show();
        $.my.popout.toggleClass('open');
        if ($card.fluxxCardUpdatesAvailable() > 0) {
          $card.fluxxCardListing().trigger('get_update.fluxx.area');
        }
      } else if ($card.is(':hidden')) {
        $('#close-modal').click();
        $.my.cards.hide();
        $card.show();
        $.my.iconlist.find('li').removeClass('selected');
        $card.data('icon').addClass('selected');
        $card.callPlugin('setUpdateArea');
        $card.resizeFluxxCard();
        $card.trigger('fluxx.card.show');
        localStorage.lastCard = $card.data('icon').find('a').attr('href');
      }
      return true;
    },
    cleanupRequest: function(options) {
      if (!(options && options.url) && $(this).fluxxCardDetail()) {
        $(this).fluxxCardDetail().hide();
      }
    },
    resizeFluxxCard : function(options) {
      var $card = $(this),
          $openModal = $('.modal');
      if ($card.is(':hidden') || $card.hasPlugin('popoutCard') || $card.is(':link')) {
        return true;
      }

      if ($openModal && $openModal.data('card') && $openModal.data('card').attr('id') == $card.attr('id')) return true;
      //if  ($card.hasClass('modal')) {
      //  // Set modal height, account for header
      //  // hard-coding for speed
      //  $card.height($card.find('.body > div').height() + ($card.find('.footer').is(':visible') ? 110 : 62));
      //  return true;
      //}

      var filters = $card.fluxxCardFilters(),
        $cardBox = $('.card-box', $card),
        $window = $(window),
        currentHeight = $window.height() - 34,
        filtersOpen = filters.is(':visible');


      if (filtersOpen) {
        $cardBox.find('.filters .body').height(currentHeight - 86);
      } else {
        methods.maximizeToolbar();

        // Normal card sizing
        if ($card.data('view') === undefined) {
          return true;
        }
        var lastHeight = $window.data('lastHeight'),
          lastCardWidth = $.my.cardTable.data('lastCardWidth'),
          cardWidth = _.addUp($('.area', $cardBox).filter(':visible'), 'outerWidth', true) + 2;

        $cardBox.find('.listing .body').height(currentHeight - 86).end().find('.detail .body').height(currentHeight);
        var detailVisible = $card.fluxxCardDetail().is(':visible'),
          listingVisible = $card.fluxxCardListing().is(':visible');

        // TODO revisit this - optimization?
        // if ( (lastCardWidth != cardWidth) ||(lastHeight != currentHeight) ) {
        //  $window.data('lastHeight', currentHeight);
        $.my.cardTable.data('lastCardWidth', cardWidth);
        var workareaWidth = $(window).width() - $('#footer').width(),
          tableAdjust = 0,
          detailAdjust = 0;
        if (cardWidth > workareaWidth) {
          tableAdjust = cardWidth - workareaWidth;
          if (tableAdjust > $.ios.defaults.maxTableAdjust) {
            detailAdjust = tableAdjust - $.ios.defaults.maxTableAdjust;
            tableAdjust = $.ios.defaults.maxTableAdjust;
            if (detailAdjust > $.ios.defaults.maxDetailAdjust) {
              detailAdjust = $.ios.defaults.maxDetailAdjust;
            }
          }
        }
        if (listingVisible) {
          if (detailVisible && !$card.data('detail-closed')) {
            tableAdjust = $.ios.defaults.cardStops[0];
            detailAdjust = $.ios.iPhoneClient ? 0 : 343 - detailAdjust;
            $card.fluxxCardDetail().data('startX', detailAdjust);
            $card.fluxxCardDetail()[0].style.webkitTransform = 'translate3d('+ detailAdjust + 'px, 0, 0)';
            $card.fluxxCardDetail()[0].style.WebkitTransition = '-webkit-transform .5s ease-out';
            methods.minimizeToolbar();
            methods.closeEditMode();
          } else {
            if ($.ios.iPhoneClient) {
              tableAdjust = 0;
            } else {
              tableAdjust = (['listing', 'summary'].indexOf($card.data('view')) === -1) ? $.ios.defaults.cardStops[0] : $.ios.defaults.cardStops[1];
            }
          }
        } else {
          // Offload adjusting the layout for a detail only card to CSS
          $card.addClass('no-listing');
          detailAdjust = 0;
          tableAdjust = $.ios.iPadClient ? $.ios.defaults.cardStops[1] - tableAdjust : 0;
        }
        $.my.cardTable[0].style.webkitTransform = 'translate3d('+ tableAdjust + 'px, 0, 0)';

        $.my.cardTable[0].style.WebkitTransition = '-webkit-transform .5s ease-out';
        $.my.cardTable.data('startX', tableAdjust);
      }
      // }
      return true;
    },
    revealArea: function(options, onComplete) {
      $(this).show();
      if (onComplete) {
        onComplete.call();
      }
      return true;
    },
    resizeFluxxStage: function(options, onComplete) {
      return true;
    },
    refreshCard: function() {
      var $card = $(this),
        $filters = $card.fluxxCardFilters();
      $card.fluxxCardAreas().refreshCardArea();
      $card.closeCardModal();
      if ($filters[0]) {
        $card.fluxxCardListing().fadeIn();
        $filters.remove();
      }
      return true;
    },
    changeView: function(view) {
      $('.card:visible:not(#popout)').find('.filters').remove();
      $(this).closeCardModal();
      return false;
    },
    renderSpreadsheet: function($area) {
      $(this).addClass('fancyTable spreadsheet fht-table fht-table-init').find('tbody tr:odd:not(:hidden)').addClass('odd');
      return true;
    },
    enabled: function() {
      var $card = $(this);
      $card.find('.titlebar .icon').html('<img src="/images/fluxx_engine/theme/ios/loaders/ActivityIndicator.png">');
      $card.find('.titlebar .icon').before('<div class="toggle-toolbar-viz"><img src="/images/fluxx_engine/theme/ios/icons/hamburger.png" width="21px" height="16px"></div>');

      //TODO: Signals panel disabled for now
      if ($card.hasPlugin('popoutCard') && $.fluxx.config.enable_notifications) return false;
      return !$card.hasPlugin('sharedCards') && !$card.hasPlugin('popoutCard');
    },
    datePicker: function(options) {
      var $elem = $(this),
        date = $.datepicker.parseDate(options.dateFormat, $elem.val());
      $elem.get(0).type = 'hidden';
      $('<input type="date" value="' + $.datepicker.formatDate('yy-mm-dd', date) + '"/>').insertAfter($elem)
        .blur(function() {
          date = $.datepicker.parseDate('yy-mm-dd', $(this).val());
          $elem.val($.datepicker.formatDate(options.dateFormat, date));
        });

      return true;
    },
    fluxxDockUpdateViewing: function() {
      return true;
    },
    selectTransfer: function() {
      return true;
    },
    showLoadingIndicator: function() {
      var $card = $(this).fluxxCard();
      var $loading = $card;
      $loading.addClass('loading').data('loading-count', Number($loading.data('loading-count') || 0) + 1);
      return true;
    },
    hideLoadingIndicator: function() {
      var $card = $(this).fluxxCard();
      var $loading = $card;
      if($loading.data('loading-count') > 1) {
        $loading.data('loading-count', Number($loading.data('loading-count')) - 1);
      } else {
        $loading.removeClass('loading').data('loading-count', 0);
      }
      return true;
    },
    cardIsMinimized: function() {
      return false || ($(this).hasPlugin('popoutCard') && !$.my.popout.hasClass('open'));
    },
    editableCardTitle: function() {
      return true;
    },
    animateWidthTo: function(widthTo, callback, speed, additonalCardWidth) {
      // Disable sliding animations
      if (callback) {
        _.bind(callback, $(this))();
      }
      return true;
    },
    minimize: function() {
      return true;
    },
    toPrompt: function() {
      $(this).data('area', $(this).fluxxCard().fluxxCardDetail()).removeClass('to-prompt').addClass('to-modal').attr('data-on-success', 'refreshCaller,close').click();
      return true;
    },
    openFilters: function(e) {
      var $elem = $(this),
        $card = $elem.fluxxCard(),
        $filters = $card ? $card.find('.area.filters') : $(this).find('.area.filters');

      $card.closeDetail();

      if ($elem.data('filter-data-source')) {
        // Open a cross-card filter
        $elem.openListingFilters(false, $card.find($elem.data('filter-data-source')), e);
      } else {
        if ($filters.length) {
          $card.changeView('listing');
        } else {
          $card.fluxxCardListing().fadeOut();
          $card.openListingFilters();
          $card.find('.header .actions a').removeClass('disabled').closest('.open-filters').toggleClass('disabled');
          $card.closeCardModal();
        }
      }
      return $card;
    },
    closeDetail: function() {
      var $card = $(this),
        $d = $card.fluxxCardDetail();
      if (!$d.fluxxCardArea().data('history')) {
        return true;
      }

      $d.one('webkitTransitionEnd', function(){
        $card.trigger('lifetimeComplete.fluxx.card');
      });

      //Use fadeout effect when closing detail on iPhone
      if ($.ios.iPhoneClient) {
        $d.fadeOut();
      } else {
        $d[0].style.MozTransitionDuration = $d[0].style.webkitTransitionDuration = '300ms';
        $d[0].style.MozTransform = $d[0].style.webkitTransform = 'translate3d(1000px,0,0)';
      }
      $d.data('history')[0] = {};
      if ($card.fluxxCardListing().is(':hidden')) {
        $(this).removeFluxxCard();
      }
      $card.saveDashboard();
      $card.data('detail-closed', true);
      return true;
    },
    cardUiArea: function(options, types) {
      return [
        '<div class="', types.join(' '), '" data-type="', options.type ,'" ', (options.drawer ? ' data-has-drawer="1" ' : null),'>',
        (options.arrow ? ['<div class="modal-arrow"></div>'] : null),

        '<div class="header"></div>',
        '<div class="body"></div>',
        '<div class="footer"></div>',
        (options.closeButton ? ['<a href="#" id="close-modal" class="buttons close-modal">Cancel</a>'] : null),
        '</div>'
      ];
    },
    titlebar: function(options) {
      return [
        '<div class="titlebar">',
        '<div class="icon"></div>',
        '<span class="title">',
        options.title,
        '</span>',
        '<ul class="buttons">',
        '<li>',
        '<a href=".header .actions" class="disabled listing-toolbar to-modal" title="Tools">',
        '<img src="/images/fluxx_engine/theme/ios/icons/more.png">',
        '</a>',
        '</li>',
        '</ul>',
        '</div>'
      ];
    },
    setMinimizedProperties: function() {
      return true;
    },
    toDashboard: function() {
      if ($(this).parents('#ios-manager-container').hasClass('edit-mode')) return true;
      if ($.modal && $.modal.close) $.modal.close();
    },
    adjustModalheight: function($modal) {
      var bodyHeight = _.addUp($modal.find('div:visible'), 'outerHeight');
      if (bodyHeight > ($(window).height()-100)) bodyHeight = $(window).height()-100;
      if (bodyHeight < 175) bodyHeight = 175;
      console.log('height: ' + bodyHeight);
      $modal.height(bodyHeight);

    },
    positionModal: function($modal, options) {

      //Close previously opened modals
      var previousModal = $('.modal');
      if (previousModal[0] && !previousModal.find('.post-signal')[0]) $('.modal').closeCardModal();
      $.my.stage.removeAttr('style');

      // We're using class names to position the modal since there are only a fixed number a positions it can be in.
      $modal.find('.body').append('<img src="/images/fluxx_engine/theme/ios/loaders/ActivityIndicator.png" class="activity-indicator">');
      var modalClass;
      if ($modal.data('target').hasClass('listing-toolbar')) {
        modalClass = 'listing-toolbar';
      } else if ($modal.data('target').hasClass('to-modal')) {
        modalClass = 'to-modal';
      } else {
        modalClass = $modal.data('target').attr('class').split(' ')[0];
      }

      $modal.addClass(modalClass).appendTo($.my.stage);

      var $blocker = $('<div id="blocker"/>');
      $blocker.insertBefore($.my.stage);
      $blocker.click(function(e){
        $modal.find('.close-modal').click();
      });

      if ($modal.hasClass('listing-toolbar')) {
        var left =  (options.event.pageX - 150),
          viewportWidth = $(window).width();

        if ((left + 300) > viewportWidth) {
          var newLeft = viewportWidth - 304;
          $modal.find('.modal-arrow').css({left: (126 + left - newLeft) + 'px'});
          left = newLeft;

        }
        $modal.css('left', left + 'px');
      }

      if ($modal.hasClass('add-card')) {
        $modal.find('.modal-arrow').css({top: + (options.event.pageY - 50) + 'px'});
      }

      setTimeout(function() {
        $modal[0].style.webkitTransform = 'translate3d(0, 0, 0)';
      },1);
      $modal.bind('refresh.fluxx.area', function (e, $target, url) {
        var $title = $('.header span', $modal);

        if ($modal.find('form').attr('method') && $modal.find('form').attr('method').toLowerCase() == 'post') {
          //There should not be a delete button for a new entry
          $modal.find('.footer .buttons').remove()
        } else {
          // Move the delete button if it exists
          $modal.find('.footer .buttons').appendTo($modal.find('.body > div'));
        }

          if (!$modal.data('items-adjusted')) {
          $modal.data('items-adjusted', true);
          var $filter = $modal.find('.open-filters').parent().prependTo($modal.find('.actions'));
          var $card = $modal.fluxxCard();

          if ($card.fluxxCardListing().data('create-link')) {
            var $create = $('<a>Create New</a>');
            $create.attr('class', '' + $card.fluxxCardListing().data('create-link-class')).attr('href', $card.fluxxCardListing().data('create-link')).show();
            if ($card.fluxxCardListing().data('create-link-title')) {
              $create.attr('title', $card.fluxxCardListing().data('create-link-title'));
            }
            $('<li/>').appendTo($modal.find('.actions')).append($create);
          }

          $modal.find('.actions').append('<li><a href="#" class="close-card" title="Close Card">Close Card</a></li>');

          if ($modal.hasClass('to-modal')) $modal.find('.footer .workflow .submit a').text('Save');
        }

        if (options.title) {
          $title.text(options.title);
        }

        // Fit modal height to content
        var bodyHeight = $modal.find('.body').height(),
          windowHeight = $(window).height(),
          previousModalHeight = previousModal.height(),
          totalModalHeights = previousModal ? previousModalHeight + bodyHeight : bodyHeight;

        if ($modal.hasClass('to-modal') || $modal.hasClass('share-card')) bodyHeight += 100;
        if (previousModal && (totalModalHeights > windowHeight - 100)) {
          previousModal.height(previousModalHeight - 100)
          bodyHeight -= 100;
        } else if (bodyHeight > windowHeight - 100) {
          bodyHeight = windowHeight - 100;
        }
        if (bodyHeight < 175) bodyHeight = 175;
        $modal.height(bodyHeight);

        $modal.find('.spellcheck').each(function() { $(this).parent().remove(); });

        if ($modal.find('.header').text() != '') {
          // Turn headers back on
          $modal.addClass('show-header');
        }


      });
      return true;
    },
    removeModal: function ($modal) {
      var $card = $modal.fluxxCard();

      $('.area', $card).not('.modal').enableFluxxArea().first().trigger('close.fluxx.modal', [$modal.data('target'), $modal.data('url')]);
      $modal.remove();
    },
    closeCardModal: function(options) {
      var $modals = $.my.stage.find('.modal'),
        $mentionModal = $.my.stage.find('.modal.mention-modal');
      if ($modals.length > 1 && $mentionModal[0]) {
        $modals = $modals.last();
      }

      return $modals.each(function(){
        var $modal = $(this);
        $('#blocker').last().remove();

        if ($modal.hasClass('listing-toolbar') || $modal.hasClass('add-card')) {
          $modal.css('opacity', 0).one('webkitTransitionEnd', function(){
            methods.removeModal($modal);
          });
        } else {
          $modal.css('height', 0).one('webkitTransitionEnd', function () {
            methods.removeModal($modal);
          });
        }
        if ($mentionModal[0]) { $mentionModal.remove(); }
      });
    }
  };


  $.fn.ios = function( method ) {
    // Method calling logic
    if ( methods[method] ) {
      return methods[ method ].apply( this, Array.prototype.slice.call( arguments, 1 ));
    } else if ( typeof method === 'object' || ! method ) {
      return methods.init.apply( this, arguments );
    }
  };
//  $.fn.iphone = function( method ) {
//    // Method calling logic
//    if ( methods[method] ) {
//      return methods[ method ].apply( this, Array.prototype.slice.call( arguments, 1 ));
//    } else if ( typeof method === 'object' || ! method ) {
//      return methods.init.apply( this, arguments );
//    }
//  }

  $(document).ready(function() {
    // AML: Get the ios app to re login when the app is switched to background mode
    if (!$.cookie('user_credentials') && !$('.error_explanation')[0] && localStorage.getItem('user_credentials')) {
      $('body').addClass('loading');
      $.cookie('user_credentials', localStorage.getItem('user_credentials'));
      window.location = '/';
    }
  });

})( jQuery );